<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="11" class="d-inline-flex">
        <h3>{{ $lang.labels.messages }}</h3>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ role: 'USER', content: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="mb-1 background-transparent" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 6px; right: 4px">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="1" style="align-self: flex-start" class="pl-1 pt-2 color-primary">
                <p>{{ i + 1 }}.</p>
              </v-col>
              <v-col cols="10">
                <v-select
                  v-model="item.role"
                  :data-cy="`${i}-role`"
                  :items="formattedRoles"
                  outlined
                  dense
                  :label="$lang.labels.role"
                  required
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit"
                  class="required-asterisk"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.content"
                  :data-cy="`${i}-content`"
                  rows="3"
                  outlined
                  dense
                  :label="$lang.labels.content"
                  required
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit"
                  class="required-asterisk"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ChatGptMessages',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    lock: true,
    deconstructedData: [],
    roles: ['SYSTEM', 'USER', 'ASSISTANT']
  }),
  computed: {
    formattedRoles() {
      return this.roles.map((role) => {
        return {
          text: this.$lang.status[role],
          value: role
        }
      })
    }
  },
  watch: {
    deconstructedData: {
      handler() {
        if (!this.lock) this.$emit('dataChanged', this.deconstructedData)
      },
      deep: true
    }
  },
  async mounted() {
    if (this.data) {
      this.deconstructedData = structuredClone(this.data)
    }
    setTimeout(() => {
      this.lock = false
    }, 20)
  },
  methods: {
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
