<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="chat-gpt"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>

    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="CHAT_GPT"
      :properties="properties"
      step-label="chat-gpt"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-model`"
        v-model="properties.model"
        outlined
        dense
        :label="$lang.labels.model"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="chat-gpt-model"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="chat-gpt-targetObject"
      />
    </v-col>

    <v-col cols="12">
      <chat-gpt-messages
        :key="`${step.id}-messages`"
        :can-edit="canEdit"
        :data="properties.messages"
        @dataChanged="handleChange('messages', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  CredentialsSelect,
  ScriptEnginesSelect
} from './components'
import ChatGptMessages from '@/pages/processes/ChatGptMessages.vue'
import StepMixin from './StepMixin'

export default {
  name: 'ChatGptStep',
  components: {
    CredentialsSelect,
    ScriptEnginesSelect,
    ChatGptMessages
  },
  mixins: [StepMixin],
  created() {
    if (!this.properties.model) this.properties.model = 'gpt-4o'
    if (!this.properties.messages) this.$set(this.properties, 'messages', [])
  }
}
</script>
